
import { Component, Vue } from "vue-property-decorator";
// import { Field } from 'vant';
// import { accountLoginRequest } from "@/service/api";
import { request } from "@/service/libs/index";
import { Toast } from "vant";

@Component({
  name: "PerfectPayType",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class PerfectPayType extends Vue {
  private payList = {
    icon: require("@/assets/paysuccess-icon.png"),
    name: "Perfect Money",
    min: 1,
    max: 2,
    moneyMin: 10,
    moneyMax: "50,000",
  };

  private money: any = "";
  private perfectResult: any = {};
  private perfectOrderResult: any = {};
  private perfectActionUrl: string = "https://perfectmoney.com/api/step1.asp";
  // 下单
  private async handleBuy() {
    Toast.loading({
      message: this.$t("loading") as string,
      forbidClick: true,
      loadingType: "spinner",
      duration: 0,
    });
    let amount: number = Number(this.money );
    let result: any = await request({
      method: "GET",
      path: "/v1/api/pay/placeOrder/sticpay?amount=" + amount,
    });
    console.log(result);
    this.perfectOrderResult = result;
    if (this.perfectOrderResult && this.perfectOrderResult.orderNo) {
      // 保存订单号在本地，已供后续成功和失败页面调用
      localStorage.setItem("stic-orderId", result.orderNo);
      window.open(result.link);
    }
  }
  // 查询单个支付渠道
  private async getPayInfoByChannelId() {
    let result: any = await request({
      method: "GET",
      path: "/v1/api/pay/channel/info?channelId=100002",
    });
    this.perfectResult = result;
  }

  //限制只能输入两位小数
  private limitInput(e: any) {
    e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
  }

  get scrollHeight(): number {
    return (
      document.documentElement.scrollTop ||
      window.pageYOffset ||
      document.body.scrollTop ||
      0
    );
  }

  private blurIn() {
    let scrollHeight = this.scrollHeight;
    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
  }

  mounted() {
    this.getPayInfoByChannelId();
    localStorage.setItem("stic-orderId", "12121");
  }
}
